@import "bootstrap";
@import "fonts";
@import "fontawesomeNew";
@import "animate";


@import "variables";

@import "swiper";
@import "typography";


@import "rd-navbar";
@import "slider";
@import "to-top";
@import "buttons";
@import "content-form";
@import "modal-form";

@import "header";
@import "about";
@import "advantages";
@import "price";
@import "how-we-work";
@import "contacts";
@import "become-supplier";
@import "footer";


html {
  /* font-size: 10px; */
  font-size: 62.5%;
}
body {
  font-family: $fontMedium;
  font-size: $default-font-size;
  color: $color-dark;
  background-color: $color-white;
}

ul {
  margin-bottom: 0;
}
ol, ul {
  list-style: none;
}
a:hover {
  text-decoration: none;
}
img {
  max-width: 100%;
}

@import "mediaqueries";