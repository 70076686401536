.how-we-work {
  position: relative;
  padding: 64px 0;
  background-image: url(../images/working-2.jpg);
  background-position: center center;
  z-index: 1;
  background-size: cover;
  overflow: hidden;
  min-height: 700px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(#152e44, .6);
  }
  &__overlay {
    background: rgba($color-blue-red, .85);
    transform: skewX(31deg);
    content: '';
    position: absolute;
    top: 0;
    width: 5000px;
    height: 100%;
    z-index: 2;
    right: 66%;
  }
  &__content {
    position: relative;
    z-index: 3;
  }
  &__item {
    color: $color-white;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    &:nth-child(2) {
      margin-left: 70px;
    }
    &:nth-child(3) {
      margin-left: 140px;
    }
    &:nth-child(4) {
      margin-left: 210px;
    }
  }
  &__item-img {
    width: 70px;
    margin-right: 50px;
    flex: 0 0 auto;
    border: 1px solid #fff;
    padding: 10px;
  }
  &__item-text {
    font-size: 20px;
    font-family: $fontBold;
  }
}
@media only screen and (max-width : 992px) {
  .how-we-work {
    &__item {

      &:nth-child(2) {
        margin-left: 0;
      }
      &:nth-child(3) {
        margin-left: 0;
      }
      &:nth-child(4) {
        margin-left: 0;
      }
    }
  }
}

@media only screen and (max-width : 480px) {
  .how-we-work {
    min-height: 500px;
    &__overlay {
      display: none;
    }
    &__item-img {
      width: 50px;
    }
    &__item-text {
      font-size: 16px;
    }
  }
}

