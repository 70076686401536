.heading-primary {
  font-family: $fontBold;
  font-size: 60px;
  color: $color-dark;
  margin-bottom: 30px;
  &--white {
    color: $color-white;
  }
  &--index {
    position: relative;
    z-index: 5;
  }
}

@media only screen and (max-width : 768px) {
  .heading-primary {
    font-size: 30px;
  }
}