.ui-to-top {
  width: 50px;
  height: 50px;
  font-size: 24px;
  line-height: 45px;
  color: $color-blue-red;
  background: $color-white;
  border-radius: 50%;
  position: fixed;
  right: 15px;
  bottom: 15px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: 20;
  transition: .3s all ease;
  border: 2px solid $color-blue-red;
  outline: none;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}

.ui-to-top:hover, .ui-to-top:focus {
  color: $color-white;
  background: $color-blue-red;
  text-decoration: none;
  outline: none;
}

.ui-to-top.active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.mobile .ui-to-top,
.tablet .ui-to-top {
  display: none !important;
}

@media (min-width: 480px) {
  .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}
