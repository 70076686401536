.advantages {
  position: relative;
  padding: 64px 0;
  background-image: url(../images/adv-bg.jpg);
  background-position: center center;
  z-index: 1;
  background-size: cover;
  overflow: hidden;
  min-height: 900px;
  &__content {
    position: relative;
    z-index: 5;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(#152e44, .6);
  }
  &__overlay {
    display: block;
    position: absolute;
    left: 113%;
    top: -110%;
    height: 340%;
    width: 10000px;
    background: rgba($color-blue-red, 0.85);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(31deg);
    transform: rotate(31deg);
    z-index: 2;
  }
  &__item {
    color: $color-white;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    &:nth-child(1) {
      margin-left: 350px;
    }
    &:nth-child(2) {
      margin-left: 280px;
    }
    &:nth-child(3) {
      margin-left: 210px;
    }
    &:nth-child(4) {
      margin-left: 140px;
    }
    &:nth-child(5) {
      margin-left: 70px;
    }
  }
  &__item-img {
    width: 70px;
    margin-right: 50px;
    flex: 0 0 auto;
    border: 1px solid #fff;
    padding: 10px;
  }
  &__item-text {
    font-size: 20px;
    font-family: $fontBold;
  }
  &__offer {
    color: $color-white;
    font-family: $fontLight;
    font-size: 12px;
  }
}

@media only screen and (max-width : 992px) {
  .advantages {
    &__item {
      color: $color-white;
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      &:nth-child(1) {
        margin-left: 0;
      }
      &:nth-child(2) {
        margin-left: 0;
      }
      &:nth-child(3) {
        margin-left: 0;
      }
      &:nth-child(4) {
        margin-left: 0;
      }
      &:nth-child(5) {
        margin-left: 0;
      }
    }
  }
}
@media only screen and (max-width : 480px) {
  .advantages {
    &__overlay {
      display: none;
    }
    &__item-img {
      width: 50px;
    }
    &__item-text {
      font-size: 16px;
    }
  }
}