.footer {
  font-family: $fontLight;
  padding: 30px;
  background: $color-dark-blue;
  color: $color-white;
  &__link-logo {
    margin-left: auto;
  }
}
.logo-sd {
  display: flex;
  align-items: center;
  & img {
    width: 42px;
    margin-right: 10px;
  }
  &__text {
    color: $color-white;
  }
}
@media only screen and (max-width : 768px) {
  .footer {
    font-size: 1.1rem;
    &__link-logo {
      margin-left: .1px;
      margin-top: 10px;
    }
  }
}