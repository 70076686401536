.btn {
  &--big {
    font-family: $fontBold;
    padding: 14px 39px;
    font-size: 18px;
    border-radius: 0;
    border-width: 2px;
    text-transform: uppercase;
    letter-spacing: .8px;

  }
  &--top {
    font-family: $fontBold;
    padding: 5px 17px;
    font-size: 14px;
    border-radius: 0;
    border-width: 2px;
    text-transform: uppercase;
    display: inline-block;
    transform: skew(-45deg);
    letter-spacing: .8px;
    & span {
      display: inline-block;
      transform: skew(45deg);
    }
  }
  &--white {
    color: $color-dark-blue;
    background-color: $color-white;
    border-color: $color-white;
    transition: all .3s ease;
    &:hover {
      color: #fff;
      background-color: $color-dark-blue;
      border-color: $color-dark-blue;
    }
  }
  &--stone {
    color: #fff;
    background-color: $color-dark-blue;
    border-color: $color-dark-blue;
    transition: all .3s ease;
    &:hover {
      color: #fff;
      background-color: transparent;
      border-color: #fff;
    }
  }
  &--hover-blue {
    &:hover {
      color: #fff;
      background-color: $color-blue-red;
      border-color: $color-blue-red;
    }
  }
}
.btn-top-opacity {
  opacity: 0;
  transition: all .3s;
}

@media only screen and (max-width : 992px) {
  .btn {
    &--top {
      display: none;
    }
  }
}

@media only screen and (max-width : 480px) {
  .btn {
    &--big {
      font-size: 16px;
      padding: 14px 30px;
    }
  }
}