/* cyrillic */
@font-face {
  font-family: 'MontserratLight';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/MontserratLight.eot");
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url("../fonts/MontserratLight.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratLight.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'MontserratLight';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/MontserratLightLatin.eot");
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url("../fonts/MontserratLightLatin.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratLightLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MontserratRegular.eot");
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url("../fonts/MontserratRegular.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratRegular.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MontserratRegularLatin.eot");
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url("../fonts/MontserratRegularLatin.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratRegularLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'MontserratMedium';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/MontserratMedium.eot");
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url("../fonts/MontserratMedium.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratMedium.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'MontserratMedium';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/MontserratMediumLatin.eot");
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url("../fonts/MontserratMediumLatin.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratMediumLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'MontserratSemiBold';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/MontserratSemiBold.eot");
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url("../fonts/MontserratSemiBold.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratSemiBold.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'MontserratSemiBold';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/MontserratSemiBoldLatin.eot");
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url("../fonts/MontserratSemiBoldLatin.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratSemiBoldLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/MontserratBold.eot");
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url("../fonts/MontserratBold.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratBold.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/MontserratBoldLatin.eot");
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url("../fonts/MontserratBoldLatin.eot?#iefix")format("embedded-opentype"),
  url(../fonts/MontserratBoldLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'impactreg';
  src: url(../fonts/impactreg.eot);
  src: local('impactreg'),
  url("../fonts/impactreg.eot?#iefix")format("embedded-opentype"),
  url('../fonts/impactreg.woff') format('woff'),
  url('../fonts/impactreg.woff2') format('woff2'),
  url('../fonts/impactreg.ttf') format('truetype');
}


@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
  url(../fonts/MaterialIcons-Regular.woff) format('woff'),
  url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}