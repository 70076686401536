.modal-form {
  font-size: 1.6rem;
  padding: 45px 30px 30px 30px;
  position: relative;
  border-radius: 0;
  border: 1px solid $color-blue-red;
  background: $color-blue-red;
  &__close {
    position: absolute;
    top: 8px;
    right: 15px;
    font-size: 30px;
    color: $color-white;
    &:hover {
      color: $color-white!important;
    }
  }
  &__heading {
    font-family: $fontBold;
    text-align: center;
    font-size: 32px;
    color: $color-white;
    margin-bottom: 30px;
  }

  font-family: $fontRegular;

  &__form-wrapper-input {
    margin-bottom: 30px;
    position: relative;
  }
  &__form-wrapper-textarea {
    margin-bottom: 30px;
    position: relative;
  }
  &__form-input {
    font-family: $fontRegular;
    display: block;
    width: 100%;
    margin: 0;
    -webkit-appearance: none;
    font-size: 15px;
    padding: 5px 18px;
    color: #777;
    background: #fff;
    border: 0;
    border-radius: 0;
    &.error {
      //border-bottom: 2px solid #a94442;
      &:focus {
        //border-bottom: 2px solid #a94442;
        //box-shadow: inset 0 0 5px rgba(#a94442, .8);
      }
    }
  }
  &__form-textarea {
    font-family: $fontRegular;
    display: block;
    width: 100%;
    margin: 0;
    -webkit-appearance: none;
    font-size: 15px;
    padding: 9px 18px;
    color: #777;
    background: #fff;
    border: 0;
    border-radius: 0;
    &.error {
      //border-bottom: 2px solid #a94442;

      &:focus {
        //border-bottom: 2px solid #a94442;
        //box-shadow: inset 0 0 5px rgba(#a94442, .8);
      }
    }
  }
  &__form-input{
    transition: .3s all ease;
    height: 50px;
    &::-webkit-input-placeholder {
      font-family: $fontRegular;
      color: $color-dark;
    }
    &::-moz-placeholder {
      font-family: $fontRegular;
      color: $color-dark;
    }
    &:-ms-input-placeholder {
      font-family: $fontRegular;
      color: $color-dark;
    }
    &:placeholder-shown {
      font-family: $fontRegular;
      color: $color-dark;
    }
    &:focus {
      outline: none;
      box-shadow: inset 0 0 10px rgba($color-blue-red, 0.55);
    }
  }
  &__form-textarea{
    transition: .3s all ease;
    height: 200px;
    &::-webkit-input-placeholder {
      font-family: $fontRegular;
      color: $color-dark;
    }
    &::-moz-placeholder {
      font-family: $fontRegular;
      color: $color-dark;
    }
    &:-ms-input-placeholder {
      font-family: $fontRegular;
      color: $color-dark;
    }
    &:placeholder-shown {
      font-family: $fontRegular;
      color: $color-dark;
    }
    &:focus {
      outline: none;
      box-shadow: inset 0 0 10px rgba($color-blue-red, 0.55);
    }
  }
  &__wrapper-checkbox {
    font-family: $fontRegular;
    position: relative;
    margin: 15px 0 30px 0;
    text-align: left;
    & a {
      color: $color-dark-blue;
      transition: .3s all ease;
      &:hover {
        color: $color-white;
      }
    }
  }
  &__form-wrapper-submit {
    margin: 15px 0;
    text-align: center;
  }
  &__btn {
    &:disabled {
      //background: #a1a1a1;
      &:hover {
        //background: lighten(#a1a1a1, 10%);
      }
    }
  }

  & label.error {
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 11px;
    color: #a94442;;
  }
  &__form-tanks {
    text-align: center;
    margin-bottom: 15px;
    font-size: 2rem;
    color: $colorRed;
  }
}
.modal-form input[type="checkbox"] + label {
  position: relative;
  display: block;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: $color-dark;
  padding-left: 45px;
  font-size: 14px;
  font-family: $fontRegular;
}
.modal-form input[type="checkbox"] + label:before {
  content: '';
  position: absolute;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 2px solid $color-dark;
  //border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  left: 15px;
  top: 50%;
  //transform: translateY(-50%);
  color: #333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.modal-form input[type="checkbox"] + label:after {
  content: '';
  position: absolute;
  width: 30px;
  height: 13px;
  border-bottom: 2px solid $color-white;
  border-left: 2px solid $color-white;
  left: 15px;
  top: 50%;
  margin-top: -7px;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

.modal-form input[type="checkbox"]:checked + label:before {
  border: 2px solid $color-white;
}
.modal-form input[type="checkbox"]:checked + label{
  color: $color-white;
  font-family: $fontRegular;
}

.modal-form input[type="checkbox"]:checked + label:after {
  opacity: 1;
  width: 36px;
}

#modalchk {
  display: none;
}

